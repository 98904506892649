<template>
  <div>
    <el-button type="primary">添加磁盘</el-button>
  </div>
</template>

<script>
export default {
  name: "DemoPage",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
