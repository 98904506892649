<template>
  <div class="box-container">
    <div class="button-wrapper">
      <div class="mb-3">
        <el-button-group style="margin-bottom: 4px">
          <el-button v-for="dist in distros" :key="dist" @click="selectDist(dist)"
            :type="selectedDist === dist ? 'primary' : 'default'" style="padding: 4px 10px" size="small">
            {{ dist }}
          </el-button>
          <el-tooltip class="item" effect="dark" content="200+发行版，在下一个版本推出" placement="right">
            <el-button @click="fetchOthersDist" :type="showOtherSelect ? 'primary' : 'default'" size="small" disabled>
              所有发行版
            </el-button>
          </el-tooltip>
        </el-button-group>
        <el-select v-if="showOtherSelect && selectedDist === ''" v-model="selectedOtherDist" placeholder="Select"
          style="width: 6rem; margin-left: 8px" size="small" filterable clearable>
          <el-option v-for="dist in otherDistros" :key="dist" :label="dist" :value="dist"></el-option>
        </el-select>
      </div>

      <div class="mb-3" style="
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #303133;
        ">
        <!-- Select Version -->
        <span style="margin-right: 4px">版本</span>
        <el-select v-model="selectedVersion" placeholder="Select version" :disabled="showOtherSelect"
          style="width: 120px; margin-right: 12px" size="small">
          <el-option v-for="version in versions" :key="version" :label="version" :value="version"></el-option>
        </el-select>

        <span style="margin-right: 4px">规格</span>
        <el-select v-model="selectedSpec" placeholder="Select specification" style="width: 80px; margin-right: 12px"
          size="small">
          <el-option v-for="spec in availableSpecs" :key="spec" :label="spec" :value="spec"></el-option>
        </el-select>

        <!-- High Spec Checkbox -->
        <el-checkbox v-model="highSpecChecked" @change="toggleHighSpec" style="margin-right: 10px">
          高规格
        </el-checkbox>

        <span style="margin-right: 4px; margin-left: 1rem">系统盘大小</span>
        <el-input-number v-model="systemDiskSize" :min="40" :max="1000" size="small" style="width: 100px"
          :step="10"></el-input-number>
        <span style="margin-left: 8px; color: #303133">GB</span>
      </div>

      <div class="mb-3">
        <el-form label-position="left" style="margin-top: 14px; font-weight: 900">
          <el-form-item label="实例数量">
            <el-input-number v-model="instanceCount" :min="show_apply_btn ? initialInstanceCount : 1" :max="5"
              style="width: 100px" @change="handleInstanceChange"></el-input-number>
            <span v-if="instanceChangeMessage" style="
                color: red;
                font-weight: bold;
                margin-left: 10px;
                margin-right: 10px;
              ">
              {{ instanceChangeMessage }}
            </span>
          </el-form-item>
        </el-form>
        <el-tooltip class="item" effect="dark" content="支持1-5个实例，支持逐个扩容（新扩容的无磁盘）。" placement="bottom">
          <el-button v-if="show_apply_btn"
            :disabled="!show_apply_btn || Math.abs(initialInstanceCount - instanceCount) !== 1" type="warning"
            @click="scaleInstances" class="apply-button" size="small">
            调整实例数量
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="所有实例的统一释放时间，北京时间" placement="bottom">
          <span v-if="release_datetime !== ''" class="release-datetime" style="margin-left: 20px; color: orange;">
            释放时间: {{ release_datetime }}
          </span>
        </el-tooltip>
      </div>

      <div class="mb-3 action-buttons" style="
          background-color: #e3f2fd;
          padding: 10px 20px;
          display: flex;
          justify-content: center;
          width: 100%;
        ">
        <el-tooltip class="item" effect="dark" content="批量并行创建" placement="bottom">
          <el-button type="primary" @click="confirmLaunchInstances">准备环境</el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="销毁所有，如果网络异常等，建议进行该操作" placement="bottom">
          <el-button type="danger" @click="confirmDestroyInstances">销毁环境</el-button>
        </el-tooltip>
        <span style="width: 6px"></span>
        <DelayReleaseButtonInstances @updateReleaseTime="handleUpdateReleaseTime"
          :delayReleaseButtonEnabled="delayReleaseButtonEnabled" />
        <span style="width: 6px"></span>

        <div class="disk-group">
          <el-tooltip class="item" effect="dark" content="为所有虚拟机添加和扩容第1块盘，最大1000GB" placement="bottom">
            <div class="button-pair group-1">
              <el-button :disabled="!diskButtonsEnabled" @click="addDisk(1)">添加磁盘1</el-button>
              <el-button :disabled="!diskButtonsEnabled" @click="expandDisk(1)">扩容</el-button>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="为所有虚拟机添加和扩容第2块盘，最大1000GB" placement="bottom">
            <div class="button-pair group-2">
              <el-button :disabled="!diskButtonsEnabled" @click="addDisk(2)">添加磁盘2</el-button>
              <el-button :disabled="!diskButtonsEnabled" @click="expandDisk(2)">扩容</el-button>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="为所有虚拟机添加和扩容第3块盘，最大1000GB" placement="bottom">
            <div class="button-pair group-3">
              <el-button :disabled="!diskButtonsEnabled" @click="addDisk(3)">添加磁盘3</el-button>
              <el-button :disabled="!diskButtonsEnabled" @click="expandDisk(3)">扩容</el-button>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div class="mb-3 mt-3" v-if="instanceCount > 0">
      <div v-for="(instance, index) in instanceCount" :key="index" class="instance-info"
        :style="{ backgroundColor: lightColors[index] }">
        <h3> 实例 {{ index + 1 }}</h3>
        <hr />
        <p>公网IP: {{ instanceInfo[`pub_ip${index + 1}`] || "" }}</p>
        <p>内部IP: {{ instanceInfo[`pri_ip${index + 1}`] || "" }}</p>
        <p>发行版: {{ selectedDist || "" }}</p>
        <p>版本号: {{ selectedVersion || "" }}</p>
        <p>规格: {{ selectedSpec || "" }}</p>
        <p>系统盘大小: {{ systemDiskSize || 40 }} GB</p>
        <p>状态: {{ instanceInfo[`status${index + 1}`] || "" }}</p>
        <div style="width: 100%">
          <p style="
              display: inline-block;
              margin-right: 6px;
              color: teal;
              margin-top: 0;
            ">
            {{ sshCommand(instanceInfo[`pub_ip${index + 1}`] || "0.0.0.0") }}
          </p>
          <el-button type="default" @click="
            copySSHCommand(instanceInfo[`pub_ip${index + 1}`] || '0.0.0.0')
            " style="display: inline-block">
            复制
          </el-button>
        </div>
        <el-button @click="
          connectInstance(
            instanceInfo[`pub_ip${index + 1}`] || '0.0.0.0',
            index
          )
          ">连接实例 {{ index + 1 }}</el-button>
      </div>
    </div>

    <!-- 添加备注输入框 -->
    <div class="mb-3">
      <el-input type="text" v-model="remark" clearable placeholder="临时备注框" maxlength="80"
        style="width: 100%;"></el-input>
    </div>

    <div class="mt-3">
      <BigTermsMacOSSimple ref="termComponent" :whost="whost" :thost="thost" :wport="wport" :port="port" :lname="lname"
        :passwd="passwd" :release_datetime="release_datetime" :termTitle="termTitle" :titleBgColor="titleBgColor"
        :borderLeftColor="borderColor" :borderRightColor="borderColor" :borderBottomColor="borderColor" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import BigTermsMacOSSimple from "./base/BigTermsMacOSSimple.vue"; // 引入新组件
import DelayReleaseButtonInstances from "./base/DelayReleaseButtonInstances.vue";
import { ElInputNumber } from "element-plus";
import { h } from 'vue';

export default {
  name: "MultiInstances",
  components: {
    BigTermsMacOSSimple, // 注册组件
    DelayReleaseButtonInstances,
    ElInputNumber,
  },
  data() {
    return {
      distros: [
        "Ubuntu",
        "Debian",
        "Rocky",
        "AlmaLinux",
        "CentOS",
        "Fedora",
        "OpenSUSE", // 更新为用户支持的发行版
      ],
      instanceChangeMessage: null, // 存储变化提示
      show_apply_btn: false,
      release_datetime: "",
      delayReleaseButtonEnabled: false,
      whost: "sre.azlinux.com",
      thost: "",
      wport: 8898,
      port: 22,
      lname: "",
      passwd: "",
      termTitle: "",
      showReleaseTime: false,
      resId: 30066,
      versions: ["22.04", "20.04", "24.04"], // 用户支持的Ubuntu版本
      specs: ["1C2G", "2C2G", "2C4G", "2C8G", "4C8G", "4C16G", "4C32G"],
      highSpecChecked: false,
      instanceCount: 1, //当前实例数量
      initialInstanceCount: 1,
      previousCount: 1, // 保存上次的实例数量值
      systemDiskSize: 40,
      showOtherSelect: false,
      otherDistros: [],
      selectedDist: "Ubuntu",
      selectedOtherDist: "",
      selectedVersion: "22.04",
      selectedSpec: "2C2G",
      previousSpec: "",
      instanceInfo: {
        pub_ip1: "",
        pub_ip2: "",
        pub_ip3: "",
        pub_ip4: "",
        pub_ip5: "",
        pri_ip1: "",
        pri_ip2: "",
        pri_ip3: "",
        pri_ip4: "",
        pri_ip5: "",
        status1: "未创建",
        status2: "未创建",
        status3: "未创建",
        status4: "未创建",
        status5: "未创建",
      },
      availableSpecs: [
        "1C2G",
        "2C2G",
        "2C4G",
        "2C8G",
        "4C8G",
        "4C16G",
        "4C32G",
      ],
      titleBgColor: "#262626", // 初始颜色
      borderColor: "#262626", // 初始边框颜色
      lightColors: ["#c8e6c9", "#7dc586", "#4fc3f7", "#ffd740", "#b0bec5"],
      termTitles: ["实例 1", "实例 2", "实例 3", "实例 4", "实例 5"],
      diskButtonsEnabled: false, // 控制磁盘按钮的可用性
      email: "", // 存储用户的电子邮件
      disk_size_1: 50, // 第1块硬盘大小
      disk_size_2: 50, // 第2块硬盘大小
      disk_size_3: 50, // 第3块硬盘大小
      remark: "", // Add this line to store the remark input
    };
  },
  watch: {
    instanceCount(newCount, oldCount) {
      if (newCount !== oldCount) {
        // console.log(`实例数量已更改：${oldCount} -> ${newCount}`);

        if (newCount < oldCount) {
          for (let i = newCount; i < oldCount; i++) {
            delete this.instanceInfo[`pub_ip${i + 1}`];
            delete this.instanceInfo[`pri_ip${i + 1}`];
            delete this.instanceInfo[`status${i + 1}`];
          }
        }
      }
    },

    // 监听 instanceInfo 中任何实例的变化
    instanceInfo: {
      handler(newInfo) {
        console.log("Instance info updated:", newInfo);

        // 检查每个实例的状态并触发相关操作
        for (let i = 1; i <= this.instanceCount; i++) {
          if (newInfo[`status${i}`] === "已创建") {
            console.log(`实例 ${i} 已创建`);
          } else if (newInfo[`status${i}`] === "已存在") {
            console.log(`实例 ${i} 已存在`);
          }
        }
      },
      deep: true, // 深度监听，监控对象内部的变化
    },
  },
  methods: {
    async scaleInstances() {
      console.log("开始执行 scaleInstances() 函数 - 增加实例数量");

      // Check if the current instance count is the same as the previous count
      if (this.instanceCount === this.previousCount) {
        ElMessage({
          message: `调整无效。实例数量无变化(${this.instanceCount}个): 请通过【+】增加，或【-】减少实例数量`,
          type: "error",
          duration: 5000,
          offset: 240,
        });
        return; // Exit the method if there is no change
      }

      // Assuming newCount is defined and holds the new instance count
      const message = `扩容中，磁盘数量和大小也会同步，数量变化: ${this.initialInstanceCount}个 -> ${this.instanceCount}个`; // Correctly use template literals
      ElMessage({
        message: message,
        type: "success",
        duration: 5000,
        offset: 240,
      });

      let email = localStorage.getItem("email");
      let email_user;

      // 检查用户登录状态
      if (email) {
        email_user = email.substring(4);
        console.log(`[扩容]已获取邮箱信息，处理后的用户标识: ${email_user}`);
      } else {
        email_user = "";
        console.warn("[扩容]未检测到登录邮箱，请先登录！");
        ElMessage({
          message: "请登录您的账号，【个人中心】",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      // 检查实例数量是否有效
      if (
        !this.instanceCount ||
        isNaN(this.instanceCount) ||
        this.instanceCount < 1 ||
        this.instanceCount > 5
      ) {
        console.error(`[扩容] 实例数量无效: ${this.instanceCount}`);
        ElMessage({
          message: "实例数量无效，请输入 1 到 5 之间的数字。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      } else {
        console.log(`[扩容] 实例数量有效: ${this.instanceCount}`);
      }

      // 检查系统磁盘大小是否有效
      if (
        !this.systemDiskSize ||
        isNaN(this.systemDiskSize) ||
        this.systemDiskSize < 40 ||
        this.systemDiskSize > 1000
      ) {
        console.error(`[扩容] 系统盘大小无效: ${this.systemDiskSize}`);
        ElMessage({
          message: "系统磁盘大小无效，请输入 40 到 1000 之间的数字。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      } else {
        console.log(`[扩容] 系统磁盘大小有效: ${this.systemDiskSize}`);
      }

      const payload = {
        instanceCount: parseInt(this.instanceCount), // 确保是数值
        systemDiskSize: parseInt(this.systemDiskSize), // 确保是数值
        selectedDist: this.selectedDist,
        selectedVersion: this.selectedVersion,
        selectedSpec: this.selectedSpec,
        email: email_user,
        id: this.resId,
      };

      console.log("[扩容] 构建的请求 payload:", payload);
      this.showReleaseTime = true;

      try {
        console.log("[扩容] 发送请求到服务器...");
        const response = await axios.post(
          "http://sre.azlinux.com:5096/scale_instances",
          payload
        );
        console.log("[扩容] 服务器响应数据:", response.data);

        if (response.data.status_code === 200) {
          console.log("[扩容] 扩容成功，开始更新 instanceInfo");

          this.show_apply_btn = true;
          this.instanceChangeMessage = null; // clear the change message

          response.data.data.instances.forEach((instance, index) => {
            this.instanceInfo[`pub_ip${index + 1}`] = instance.public_ipv4;
            this.instanceInfo[`pri_ip${index + 1}`] = instance.inner_ipv4;
            this.instanceInfo[`status${index + 1}`] = "已创建";
            console.log(`实例 ${index + 1} 信息已更新:`, {
              pub_ip: instance.public_ipv4,
              pri_ip: instance.inner_ipv4,
              zone_id: instance.zone_id,
              status: "已创建",
            });
          });

          this.passwd = response.data.data.passwd;
          this.release_datetime = response.data.data.release_datetime;
          this.port = response.data.data.port;
          this.whost = response.data.data.whost;
          this.wport = response.data.data.wport;
          this.lname = response.data.data.username;

          console.log("连接参数已更新:", {
            passwd: this.passwd,
            port: this.port,
            whost: this.whost,
            wport: this.wport,
            lname: this.lname
          });

          this.instanceCount = response.data.data.instances.length;
          this.initialInstanceCount = this.instanceCount;

          // 成功后显示启动成功的提示
          ElMessage({
            message: "实例扩容成功！同规格大小磁盘已同步创建。",
            type: "success",
            duration: 5000,
            offset: 240,
          });
        } else {
          console.warn("实例扩容失败");
          ElMessage({
            message: "实例扩容失败，请重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      } catch (error) {
        console.error("扩容后启动实例时出错:", error);

        if (error.response) {
          const { status, data } = error.response;
          console.warn(`HTTP 错误状态码: ${status}`, "错误响应内容:", data);

          if (status === 409) {
            ElMessage({
              message: data.error || "用户已经存在多实例任务，请稍后再试。",
              type: "error",
              duration: 3000,
              offset: 240,
            });

            // 即使是409错误，也设置必要的连接信息
            if (data.active_task) {
              this.show_apply_btn = true;
              this.release_datetime = data.active_task.release_datetime;
              this.delayReleaseButtonEnabled = true;
              this.passwd = data.active_task.passwd;  // 设置密码
              this.diskButtonsEnabled = true; // 启用磁盘按钮

              if (data.active_task.instances) {
                console.log("已存在的实例信息:", data.active_task.instances);

                this.instanceCount = data.active_task.instances.length;
                this.initialInstanceCount = this.instanceCount;

                data.active_task.instances.forEach((instance, index) => {
                  this.instanceInfo[`pub_ip${index + 1}`] = instance.public_ipv4;
                  this.instanceInfo[`pri_ip${index + 1}`] = instance.inner_ipv4;
                  this.instanceInfo[`status${index + 1}`] = "已存在";
                  console.log(`实例 ${index + 1} 已存在，状态更新:`, {
                    pub_ip: instance.public_ipv4,
                    pri_ip: instance.inner_ipv4,
                    zone_id: instance.zone_id,
                    status: "已存在",
                  });
                });

                // 打印连接参数以便调试
                console.log("连接参数已更新:", {
                  passwd: this.passwd,
                  release_datetime: this.release_datetime
                });
              }
            }
          } else {
            ElMessage({
              message: `请求失败，请检查网络或重试。错误代码：${status}`,
              type: "error",
              duration: 3000,
              offset: 240,
            });
          }
        } else {
          console.error("请求没有响应，可能是网络或服务器故障。");
          ElMessage({
            message: "网络错误，请检查连接。可能是服务器故障。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      }
    },
    handleInstanceChange(newCount) {
      if (this.show_apply_btn) {  // 已经创建了实例
        if (newCount < this.initialInstanceCount) {
          // 如果用户试图减少实例数量
          ElMessage({
            message: "您可以 poweroff 对应的虚拟机即可达到减少实例数量的目的",
            type: "warning",
            duration: 3000,
            offset: 240,
          });
          // 恢复到原来的实例数量
          this.instanceCount = this.initialInstanceCount;
        } else {
          // 只在增加数量时才显示变化消息
          this.instanceChangeMessage = `${this.initialInstanceCount} -> ${newCount}`;
        }
      } else {
        // 在创建实例之前，允许自由调整数量
        this.instanceChangeMessage = null;
      }
    },
    setTitleColor(index) {
      // 更新 titleBgColor 为 lightColors 中对应的颜色
      this.titleBgColor = this.lightColors[index % this.lightColors.length];
    },
    handleUpdateReleaseTime(newReleaseTime) {
      this.release_datetime = newReleaseTime;
      console.log("New release time after delay: ", newReleaseTime);
    },
    handleDelayReleaseSuccess() {
      console.log("Delay release success.");
    },
    selectDist(dist) {
      this.selectedDist = dist;
      this.showOtherSelect = false;
      if (dist === "Ubuntu") {
        this.versions = ["22.04", "20.04", "24.04"];
      } else if (dist === "Debian") {
        this.versions = ["12"];
        this.selectedVersion = "12";
      } else if (dist === "Rocky") {
        this.versions = ["9"];
        this.selectedVersion = "9";
      } else if (dist === "AlmaLinux") {
        this.versions = ["9"];
        this.selectedVersion = "9";
      } else if (dist === "CentOS") {
        this.versions = ["stream_9", "stream_8", "7.9", "6.10"];
        this.selectedVersion = "stream_9";
      } else if (dist === "Fedora") {
        this.versions = ["40"];
        this.selectedVersion = "40";
      } else if (dist === "OpenSUSE") {
        this.versions = ["15.6"];
        this.selectedVersion = "15.6";
      }
    },
    fetchOthersDist() {
      this.selectedDist = "";
      this.selectedVersion = "";
      (this.selectedSpec = ""), (this.showOtherSelect = true);
      this.otherDistros = [
        "Gentoo",
        "Arch Linux",
        "Clear Linux",
        "FreeBSD",
        "Kali Linux",
      ];
    },
    confirmLaunchInstances() {
      // 弹出确认对话框
      ElMessageBox.confirm(
        `您将创建 ${this.instanceCount} 台虚拟机，是否继续？`,
        "确认创建",
        {
          confirmButtonText: "继续创建",
          cancelButtonText: "取消",
          type: "warning",
          distinguishCancelAndClose: true, // 明确区分关闭和取消
        }
      )
        .then(() => {
          // 用户点击继续创建，调用真正的请求
          this.launchInstances();
        })
        .catch(() => {
          // 用户点击取消，什么都不做
          console.log("用户取消了创建虚拟机");
        });
    },
    async launchInstances() {
      console.log("开始执行 launchInstances() 函数 - 准备环境");

      let email = localStorage.getItem("email");
      let email_user;

      if (!email) {
        //   console.error("Email is not defined or is null.");
        console.warn("未检测到登录邮箱，请先登录！");
        ElMessage({
          message: "请点击【个人中心】，登录您的账号。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      } else if (email.length < 5) {
        // console.warn("Email is too short to extract user identifier.");
        ElMessage({
          message: "请点击【个人中心】，登录您的账号。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      // 检查用户登录状态
      if (email) {
        email_user = email.substring(4);
        console.log(`已获取邮箱信息，处理后的用户标识: ${email_user}`);
      } else {
        email_user = "";
        console.warn("未检测到登录邮箱，请先登录！");
        ElMessage({
          message: "请点击【个人中心】，登录您的账号，",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      // 检查实例数量是否有效
      if (
        !this.instanceCount ||
        isNaN(this.instanceCount) ||
        this.instanceCount < 1 ||
        this.instanceCount > 5
      ) {
        console.error(`实例数量无效: ${this.instanceCount}`);
        ElMessage({
          message: "实例数量无效，请输入 1 到 5 之间的数字。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      } else {
        console.log(`实例数量有效: ${this.instanceCount}`);
      }

      // 检查系统磁盘大小是否有效
      if (
        !this.systemDiskSize ||
        isNaN(this.systemDiskSize) ||
        this.systemDiskSize < 40 ||
        this.systemDiskSize > 1000
      ) {
        console.error(`系统盘大小无效: ${this.systemDiskSize}`);
        ElMessage({
          message: "系统磁盘大小无效，请输入 40 到 1000 之间的数字。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      } else {
        console.log(`系统磁盘大小有效: ${this.systemDiskSize}`);
      }

      ElMessage({
        message: "实例启动中，请稍候...",
        type: "success",
        duration: 5000,
        offset: 240,
      });

      const payload = {
        instanceCount: parseInt(this.instanceCount), // 确保是数值
        systemDiskSize: parseInt(this.systemDiskSize), // 确保是数值
        selectedDist: this.selectedDist,
        selectedVersion: this.selectedVersion,
        selectedSpec: this.selectedSpec,
        email: email_user,
        id: this.resId,
      };

      console.log("构建的请求 payload:", payload);
      this.showReleaseTime = true;

      try {
        console.log("发送请求到服务器...");
        const response = await axios.post(
          "http://sre.azlinux.com:5056/create_multi_instances",
          payload
        );
        console.log("服务器响应数据:", response.data);

        if (response.data.status_code === 200) {
          console.log("实例创建成功，开始更新 instanceInfo");

          this.show_apply_btn = true;

          response.data.data.instances.forEach((instance, index) => {
            this.instanceInfo[`pub_ip${index + 1}`] = instance.public_ipv4;
            this.instanceInfo[`pri_ip${index + 1}`] = instance.inner_ipv4;
            this.instanceInfo[`status${index + 1}`] = "已创建";
            console.log(`实例 ${index + 1} 信息已更新:`, {
              pub_ip: instance.public_ipv4,
              pri_ip: instance.inner_ipv4,
              zone_id: instance.zone_id,
              status: "已创建",
            });
          });

          this.passwd = response.data.data.passwd;
          this.release_datetime = response.data.data.release_datetime;
          this.port = response.data.data.port;
          this.whost = response.data.data.whost;
          this.wport = response.data.data.wport;
          this.lname = response.data.data.username;

          console.log("连接参数已更新:", {
            passwd: this.passwd,
            port: this.port,
            whost: this.whost,
            wport: this.wport,
            lname: this.lname
          });

          this.delayReleaseButtonEnabled = true;
          this.instanceCount = response.data.data.instances.length;
          this.initialInstanceCount = this.instanceCount;
          this.diskButtonsEnabled = true; // 启用磁盘按钮

          // 成功后显示启动成功的提示
          ElMessage({
            message: "实例已设置，开始启动！约十几秒后可连接。",
            type: "success",
            duration: 3000,
            offset: 240,
          });
        } else {
          console.warn("实例启动失败，响应代码不为200");
          ElMessage({
            message: "实例启动失败，请重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      } catch (error) {
        console.error("启动实例时出错:", error);

        if (error.response) {
          const { status, data } = error.response;
          console.warn(`HTTP 错误状态码: ${status}`, "错误响应内容:", data);

          if (status === 409) {
            ElMessage({
              message: data.error || "用户已经存在多实例任务，请稍后再试。",
              type: "error",
              duration: 3000,
              offset: 240,
            });

            // 即使是409错误，也设置必要的连接信息
            if (data.active_task) {
              this.show_apply_btn = true;
              this.release_datetime = data.active_task.release_datetime;
              this.delayReleaseButtonEnabled = true;
              this.passwd = data.active_task.passwd;  // 设置密码
              this.diskButtonsEnabled = true; // 启用磁盘按钮

              if (data.active_task.instances) {
                console.log("已存在的实例信息:", data.active_task.instances);

                this.instanceCount = data.active_task.instances.length;
                this.initialInstanceCount = this.instanceCount;

                data.active_task.instances.forEach((instance, index) => {
                  this.instanceInfo[`pub_ip${index + 1}`] = instance.public_ipv4;
                  this.instanceInfo[`pri_ip${index + 1}`] = instance.inner_ipv4;
                  this.instanceInfo[`status${index + 1}`] = "已存在";
                  console.log(`实例 ${index + 1} 已存在，状态更新:`, {
                    pub_ip: instance.public_ipv4,
                    pri_ip: instance.inner_ipv4,
                    zone_id: instance.zone_id,
                    status: "已存在",
                  });
                });

                // 打印连接参数以便调试
                console.log("连接参数已更新:", {
                  passwd: this.passwd,
                  release_datetime: this.release_datetime
                });
              }
            }
          } else {
            ElMessage({
              message: `请求失败，请检查网络或重试。错误代码：${status}`,
              type: "error",
              duration: 3000,
              offset: 240,
            });
          }
        } else {
          console.error("请求没有响应，可能是网络或服务器故障。");
          ElMessage({
            message: "网络错误，请检查连接。可能是服务器故障。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      }
    },
    confirmDestroyInstances() {
      // 弹出确认对话框
      ElMessageBox.confirm(
        `您将销毁 ${this.instanceCount} 台虚拟机，是否继续？`,
        "确认销毁",
        {
          confirmButtonText: "继续销毁",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: 'el-button--danger', // 确认按钮的颜色与销毁按钮相同
        }
      )
        .then(() => {
          // 用户点击继续销毁，调用真正的请求
          this.destroyInstances();
        })
        .catch(() => {
          // 用户点击取消，什么都不做
          console.log("用户取消了销毁虚拟机");
        });
    },
    async destroyInstances() {
      console.log("准备销毁环境");

      // 获取 email 并进行校验
      let email = localStorage.getItem("email");
      let email_user;

      if (email) {
        email_user = email.substring(4);
      } else {
        email_user = "";
        ElMessage({
          message: "请登录您的账号，【个人中心】",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      this.showReleaseTime = false;

      ElMessage({
        message: "已启动销毁流程，耗时1分钟以内，您可以进行其他操作。",
        type: "success",
        duration: 3000,
        offset: 240,
      });

      // 重置状态到初始值
      this.resetInstanceInfo();

      // 设置 payload 数据，包含 email、delete_security_code 和 id
      const payload = {
        email: email_user,
        delete_security_code: "sre_alinux_c0m",
        id: this.resId,
      };

      try {
        const response = await axios.delete(
          "http://sre.azlinux.com:5056/delete_multi_instances",
          {
            data: payload,
          }
        );

        if (response.status === 200) {
          console.log("环境销毁成功");
          ElMessage({
            message: "环境已成功销毁",
            type: "success",
            duration: 3000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "环境销毁失败，请重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      } catch (error) {
        console.error("销毁环境时出错:", error);

        // 检查错误响应状态码
        if (error.response) {
          const { status, data } = error.response;
          if (status === 404) {
            ElMessage({
              message: "您没有要删除的多实例资源，无需【销毁环境】",
              type: "warning",
              duration: 3000,
              offset: 240,
            });
          } else if (status === 409) {
            ElMessage({
              message: data.error || "环境销毁冲突，已有其他活动任务。",
              type: "error",
              duration: 3000,
              offset: 240,
            });
          } else {
            ElMessage({
              message: `请求失败，请检查网络或重试。错误代码：${status}`,
              type: "error",
              duration: 3000,
              offset: 240,
            });
          }
        } else {
          ElMessage({
            message: "网络错误，请检查连接。可能是服务器故障。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      }
    },
    resetInstanceInfo() {
      // 重置状态到初始值的逻辑
      this.instanceInfo = {
        pub_ip1: "",
        pub_ip2: "",
        pub_ip3: "",
        pub_ip4: "",
        pub_ip5: "",
        pri_ip1: "",
        pri_ip2: "",
        pri_ip3: "",
        pri_ip4: "",
        pri_ip5: "",
        status1: "未创建",
        status2: "未创建",
        status3: "未创建",
        status4: "未创建",
        status5: "未创建",
      };
      this.passwd = ""; // 清空密码
      this.release_datetime = ""; // 清空释放时间
      this.instanceCount = 1; // 恢复实例数量
      this.initialInstanceCount = 1; // 恢复初始实例数量
      this.show_apply_btn = false; // 隐藏应用按钮
      this.instanceChangeMessage = null; // 清空实例变化消息
      this.highSpecChecked = false; // 恢复高规格复选框状态
      this.selectedSpec = "2C2G"; // 恢复默认规格
      this.selectedVersion = "22.04"; // 恢复默认版本
      this.selectedDist = "Ubuntu"; // 恢复默认发行版
    },
    delayRelease() {
      console.log("延迟释放");
      // 这里添加你的逻辑
    },
    async addDisk(diskNumber) {
      const diskLabel = diskNumber === 1 ? '添加第 1 块磁盘' :
        diskNumber === 2 ? '添加第 2 块磁盘' :
          '添加第 3 块磁盘';

      // 设置默认的磁盘大小为100
      let diskSize = 100; // 默认值为100

      try {
        await ElMessageBox({
          title: diskLabel,
          message: h('div', {}, [
            h('span', {}, '磁盘大小 (40-1000) GB'),
            // 使用原生input元素来接收数字输入
            h('input', {
              type: 'number',
              value: diskSize, // 初始值为100
              min: 40,
              max: 1000,
              step: 1,
              style: {
                width: '140px',
                marginLeft: '10px'
              },
              onInput: (event) => {
                diskSize = Number(event.target.value); // 更新 diskSize
              }
            }),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done(); // 直接关闭对话框，因为值已经被自动调整到有效范围内
            } else {
              done(); // 取消时直接关闭
            }
          },
        });
      } catch (error) {
        if (error === 'cancel') {
          console.log("用户取消了添加磁盘");
          return; // 返回避免后续逻辑
        }
        console.error("弹出对话框时出错:", error);
        return;
      }

      let email = localStorage.getItem("email");
      let email_user;

      if (email) {
        email_user = email.substring(4);
      } else {
        ElMessage({
          message: "请登录您的账号，【个人中心】",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      console.log("准备发送请求，参数如下：");
      console.log("Email:", email_user);
      console.log("Disk Size:", diskSize);
      console.log("Disk Number:", diskNumber);

      const apiUrl = `http://sre.azlinux.com:5094/instances_attach_disk${diskNumber}`;

      try {
        const response = await axios.post(apiUrl, {
          email: email_user,
          diskSize: diskSize,
          diskNo: diskNumber,
        });

        if (response.status === 200) {
          ElMessage({
            message: `所有实例成功添加磁盘${diskNumber}，大小为${diskSize}GB`,
            type: "success",
            duration: 3000,
            offset: 240,
          });
        } else {
          // 显示后端返回的错误信息
          ElMessage({
            message: response.data.message || "添加磁盘失败，请重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      } catch (error) {
        console.error("添加磁盘时出错:", error);

        // 处理后端返回的错误消息
        if (error.response && error.response.data && error.response.data.message) {
          ElMessage({
            message: error.response.data.message,
            type: "error",
            duration: 3000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "请求失败，请检查网络或重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      }
    }
    ,
    async expandDisk(diskNumber) {
      console.log("扩容磁盘函数调用，传入的 diskNumber:", diskNumber);

      // 根据磁盘编号设置扩容提示标签
      const diskLabel = diskNumber === 1 ? '扩容第 1 块磁盘' :
        diskNumber === 2 ? '扩容第 2 块磁盘' :
          '扩容第 3 块磁盘';

      let currentDiskSize = 0; // 用于存储从接口查询到的磁盘当前容量
      let newDiskSize = 0; // 扩容 new disk size

      // 从 localStorage 获取用户的 email
      let email = localStorage.getItem("email");
      let email_user;

      // 如果没有登录，提示用户登录
      if (email) {
        email_user = email.substring(4); // 假设 email 格式是 'user_XXXX'
      } else {
        ElMessage({
          message: "请登录您的账号，【个人中心】",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      // 获取磁盘当前容量的接口
      const queryApiUrl = `http://sre.azlinux.com:5095/get_disk_size_${diskNumber}`;

      console.log("获取磁盘当前容量...");
      try {
        // 调用接口获取磁盘当前容量
        const queryResponse = await axios.post(queryApiUrl, {
          email: email_user, // 后端接口需要传递 email 参数
          disk_no: diskNumber, // 确保参数名为 disk_no，符合后端接口的定义
        });

        // 检查响应数据是否有效
        if (queryResponse.status === 200 && queryResponse.data) {
          currentDiskSize = queryResponse.data[`disk_size_${diskNumber}`]; // 动态提取磁盘当前容量
          newDiskSize = Math.floor(Math.min(currentDiskSize + 100, 1000)); // 确保 newDiskSize 为整数

        } else {
          ElMessage({
            message: "获取磁盘当前容量失败，请稍后重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
          return;
        }
      } catch (error) {
        console.error("获取磁盘当前容量时出错:", error);
        ElMessage({
          message: "请求失败，无��获取磁盘当前容量。",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      console.log(`磁盘当前容量为 ${currentDiskSize} GB`);

      try {
        // 弹出对话框让用户输入扩容后的磁盘大小
        await ElMessageBox({
          title: diskLabel,
          message: h('div', {}, [
            h('span', {}, `磁盘当前容量 ${currentDiskSize} GB`),
            h('input', {
              type: 'number',
              value: newDiskSize,
              min: currentDiskSize + 1, // 扩容大小必须大于当前容量
              max: 1000,
              step: 1,
              style: {
                width: '140px',
                marginLeft: '10px'
              },
              onInput: (event) => {
                newDiskSize = Number(event.target.value); // 更新输入值
              }
            }),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done();
            } else {
              done();
            }
          },
        });
      } catch (error) {
        if (error === 'cancel') {
          console.log("用户取消了扩容磁盘");
          return;
        }
        console.error("弹出对话框时出错:", error);
        return;
      }

      console.log("准备发送请求，参数如下：");
      console.log("Email:", email_user);
      console.log("New Disk Size:", newDiskSize);
      console.log("Disk Number:", diskNumber);

      const apiUrl = `http://sre.azlinux.com:5095/expand_disk_${diskNumber}`;

      try {
        // 发送扩容请求
        const response = await axios.post(apiUrl, {
          email: email_user,
          diskNo: diskNumber,
          diskNewSize: newDiskSize,
        });

        // 根据响应结果处理
        if (response.status === 200) {
          ElMessage({
            message: `成功扩容磁盘${diskNumber}，新容量为${newDiskSize}GB`,
            type: "success",
            duration: 3000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: response.data.message || "扩容磁盘失败，请重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      } catch (error) {
        console.error("扩容磁盘时出错:", error);

        // 如果后端返回了错误消息，展示它
        if (error.response && error.response.data && error.response.data.message) {
          ElMessage({
            message: error.response.data.message,
            type: "error",
            duration: 3000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "请求失败，请检查网络或重试。",
            type: "error",
            duration: 3000,
            offset: 240,
          });
        }
      }
    }

    ,
    toggleHighSpec() {
      if (this.highSpecChecked) {
        // 保存当前选中的规格
        this.previousSpec = this.selectedSpec;

        // 切换到高规格
        this.availableSpecs = ["8C16G", "8C32G"];
        this.selectedSpec = "8C16G"; // 默认选择高规格
      } else {
        // 恢复到之前保存的规格
        this.availableSpecs = this.specs;
        this.selectedSpec = this.previousSpec;
      }
    },
    sshCommand(ip) {
      return `ssh sre@${ip}`;
    },
    async copySSHCommand(ip) {
      if (ip === '0.0.0.0') {
        this.$message({
          message: "该实例尚未创建，请点击【准备环境】或【调整实例数量】",
          type: "error",
          offset: 240,
        });
        return;
      }

      const sshCmd = this.sshCommand(ip);

      if (!sshCmd) {
        this.$message({
          message: "无法生成 SSH 命令，请检查实例状态。",
          type: "error",
          offset: 240,
        });
        return;
      }

      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(sshCmd);
          this.$message({
            message: `SSH 连接命令已复制: ${sshCmd}`,
            type: "success",
            duration: 3000,
            offset: 240,
          });
        } catch (err) {
          this.$message({
            message: "复制命令失败，请手动复制。",
            type: "error",
            offset: 240,
          });
          console.error('复制失败:', err);
        }
      } else {
        this.$message({
          message: "当前运行模式【http】不支持复制功能，请手动复制命令。",
          type: "error",
          offset: 240,
        });
      }
    },
    connectInstance(ip, index) {
      // 获取内网IP
      const internalIp = this.instanceInfo[`pri_ip${index + 1}`];

      if (ip === "0.0.0.0" || !internalIp) {
        this.$message({
          message: "实例尚未创建，无法连接。请点击【准备环境】",
          type: "error",
          offset: 240,
        });
        return;
      }

      // 检查密码是否存在
      if (!this.passwd) {
        this.$message({
          message: "连接密码未设置，请先准备环境",
          type: "error",
          offset: 240,
        });
        return;
      }

      // 设置所有必要的连接参数
      this.thost = internalIp;  // 使用内网IP而不是公网IP
      this.lname = "sre";

      // 设置颜色和标题
      const selectedColor = this.lightColors[index];
      this.titleBgColor = selectedColor;
      this.borderColor = selectedColor;  // 设置边框颜色
      this.termTitle = this.termTitles[index];

      // console.log("Connecting with params:", {
      //   thost: this.thost,        // 这里会显示内网IP
      //   whost: this.whost,
      //   wport: this.wport,
      //   port: this.port,
      //   lname: this.lname,
      //   passwd: this.passwd
      // });

      // 调用子组件的 connectBox 方法
      this.$nextTick(() => {
        if (this.$refs.termComponent) {
          this.$refs.termComponent.connectBox();
        }
      });

      this.$message({
        message: `连接实例${index + 1}: ${internalIp}`,  // 更简洁的消息
        type: "success",
        duration: 2000,  // 缩短显示时间到2秒
        offset: 260,
      });
    },
  },
};
</script>

<style scoped>
.box-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-button {
  padding: 2px 6px;
}

.mb-3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ml-2 {
  margin-left: 16px;
}

.mt-3 {
  margin-top: 16px;
}

.instance-info {
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 13px;
  text-align: left;
  min-width: 144px;
}

h3 {
  font-weight: 400;
  font-style: italic;
}

.release-datetime {
  font-size: 14px;
  color: #555;
  margin-left: 6px;
}

.loading-spinner {
  text-align: center;
  font-size: 18px;
}

.button-pair {
  display: inline-flex;
  padding: 4px;
  border-radius: 4px;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.disk-group {
  display: flex;
  gap: 12px;
  /* Add some space between disk groups */
  margin-left: 16px;
  /* Adjust as needed for horizontal alignment */
}

.group-1 {
  background-color: #f8bbd0;
  /* Light Pink */
}

.group-2 {
  background-color: #f48fb1;
  /* Soft Orange */
}

.group-3 {
  background-color: #f06292;
  /* Light Yellow */
}


.apply-button {
  margin-left: 10px;
}
</style>
