<template>
  <div class="cmd-container">
    <div class="cmd-header">Windows命令提示符 (示例, 未连接)</div>
    <div class="cmd-output" ref="output">
      <pre>{{ cmdOutput }}</pre>
      <!-- 命令提示符和输入框在同一行 -->
      <div class="cmd-line">
        <span>C:\Users\Administrator></span>
        <input
          type="text"
          v-model="currentCommand"
          @keyup.enter="executeCommand"
          class="cmd-input-inline"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WindowsCmdDemo",
  props: {
    initialCommand: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cmdOutput: "",
      currentCommand: "", // 初始为空，通过watch设置
    };
  },
  watch: {
    initialCommand: {
      immediate: true, // 初始化时立即执行
      handler(newValue) {
        this.currentCommand = newValue;
      },
    },
  },
  methods: {
    executeCommand() {
      // 显示命令并重置输入框
      this.cmdOutput += `C:\\Users\\Administrator> ${this.currentCommand.trim()}\n`;
      this.currentCommand = ""; // 清空输入框
      this.$nextTick(() => {
        // 自动滚动到输出的最后一行
        this.$refs.output.scrollTop = this.$refs.output.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.cmd-container {
  background-color: black;
  color: white;
  font-family: "Courier New", Courier, monospace;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 8px;
  width: 80%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cmd-header {
  margin-bottom: 10px;
  text-align: left;
  font-size: 15px;
}

.cmd-output {
  flex: 1;
  overflow-y: auto;
  text-align: left;
  white-space: pre-wrap;
}

.cmd-line {
  display: flex;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.2;
}

.cmd-input-inline {
  background-color: black;
  color: white;
  border: none;
  outline: none;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.2;
  padding-left: 8px;
  margin: 0;
  height: auto;
  width: 100%;
}

.cmd-output pre {
  margin: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.2;
}
</style>
