<template>
  <div class="container">
    <div>
      <el-radio-group
        v-model="selectedOS"
        @change="handleOSChange"
        size="small"
      >
        <el-radio-button label="Ubuntu">Ubuntu</el-radio-button>
        <el-radio-button label="Debian">Debian</el-radio-button>
        <el-radio-button label="CentOS">CentOS</el-radio-button>
        <el-radio-button label="Fedora">Fedora</el-radio-button>
        <el-radio-button label="Rocky">Rocky</el-radio-button>
        <el-radio-button label="AlmaLinux">AlmaLinux</el-radio-button>
        <el-radio-button label="OpenSUSE" v-if="!foreign"
          >OpenSUSE</el-radio-button
        >
      </el-radio-group>

      <div class="control-panel">
        <el-select
          v-model="selectedOSVersion"
          placeholder="请选择版本"
          class="version-select"
          size="small"
        >
          <el-option
            v-for="version in versions"
            :key="version"
            :label="version"
            :value="version"
          >
          </el-option>
        </el-select>
        <el-tooltip
          content="服务器位于中国大陆以外，仅限【运维练习】用途！实例支持1C2G, 2C2G,2C4G"
          placement="bottom"
          effect="dark"
        >
          <el-checkbox
            v-model="foreign"
            @change="handleForeignChange"
            label="国外"
            >国外</el-checkbox
          >
        </el-tooltip>
        <ComputeSpecs
          :isForeign="foreign"
          :selectedValueFromParent="selectedValue"
          @update:selectedValue="handleSelectedValue"
        />

        <el-tooltip
          class="box-item"
          effect="dark"
          content="约耗时10-30秒，如无法连接，请5秒后再【连接环境】。"
          placement="bottom"
        >
          <el-button type="success" @click="getOnlineServer"
            >准备环境</el-button
          >
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="如不再使用，可【销毁环境】。"
          placement="bottom"
        >
          <el-button
            type="danger"
            @click="destroyMyOnlineServer"
            :disabled="!isDestroyOSEnabled"
            >销毁环境</el-button
          >
        </el-tooltip>
        <span style="width: 6px"></span>
        <DelayReleaseButton
          @updateReleaseTime="handleUpdateReleaseTime"
          :delayReleaseButtonEnabled="delayReleaseButtonEnabled"
        />
        <span style="width: 6px"></span>

        <el-tooltip
          class="box-item"
          effect="dark"
          content="创建磁盘并挂载到实例，单盘数值为20~100 GB，国外实例不支持。"
          placement="top"
        >
          <el-button
            @click="showInput = !showInput"
            v-if="!foreign"
            color="#626aef"
            :disabled="!isDataDiskEnabled"
          >
            {{ showInput ? "隐藏" : "挂盘" }}
          </el-button>
        </el-tooltip>
        <div v-if="showInput">
          <el-input
            v-model.number="diskSize"
            placeholder="20"
            min="20"
            max="100"
            style="width: 50px"
          >
          </el-input>

          <el-button @click="confirmAndCreate" color="#626aef">
            挂载
          </el-button>
        </div>
        <span style="width: 6px"></span>

        <el-tooltip
          class="box-item"
          effect="dark"
          content="对实例挂载的第一块盘进行扩容，数值要比原始的值要大！国外实例不支持。"
          placement="top"
        >
          <el-button
            @click="showInputExpand = !showInputExpand"
            v-if="!foreign"
            color="#01579b"
            :disabled="!isNewDiskSizeEnabled"
          >
            {{ showInputExpand ? "隐藏" : "扩盘" }}
          </el-button>
        </el-tooltip>
        <div v-if="showInputExpand">
          <el-input
            v-model.number="diskNewSize"
            placeholder="10"
            min="10"
            max="50"
            style="width: 50px"
          >
          </el-input>
          <el-button @click="confirmAndExpand" color="#01579b">
            扩容
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <BigTermsMacOS
    :whost="whost"
    :thost="thost"
    :wport="wport"
    :port="port"
    :lname="lname"
    :passwd="passwd"
    :connectButtonEnabled="connectButtonEnabled"
    :release_datetime="release_datetime"
  />
</template>
<script>
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import ComputeSpecs from "../views/base/ComputeSpecs.vue";
import BigTermsMacOS from "./base/BigTermsMacOS.vue";
import DelayReleaseButton from "./base/DelayReleaseButton.vue";

export default {
  data() {
    return {
      showInput: false,
      showInputExpand: false,
      diskSize: 20,
      diskNewSize: 0,
      isDialogVisible: false,
      selectedOS: "Ubuntu",
      selectedOSVersion: "",
      versions: [],
      osVersions: {
        Ubuntu: ["22.04", "20.04", "24.04"],
        CentOS: ["stream_9", "stream_8", "7.9", "6.10"],
        OpenSUSE: ["15.6"],
        Debian: ["12"],
        Rocky: ["9"],
        AlmaLinux: ["9"],
        Fedora: ["40"],
      },
      foreign: false,
      isDestroyOSEnabled: false,
      isDataDiskEnabled: false,
      isNewDiskSizeEnabled: false,
      resId: 20061,
      responseData: null,
      whost: "sre.azlinux.com",
      wport: 8858,
      thost: "sre.azlinux.com",
      port: 22,
      lname: "sre",
      passwd: "YXpMaW51eC5jb20z",
      hint2: "",
      release_datetime: "",
      connectButtonEnabled: false,
      delayReleaseButtonEnabled: false,
      selectedValue: "1C2G",
    };
  },
  mounted() {
    this.handleOSChange(this.selectedOS);
    this.diskNewSize = this.diskSize + 10;
  },
  beforeUnmouted() {
    console.log("Leave");
  },
  methods: {
    confirmAndCreate() {
      if (
        isNaN(this.diskSize) ||
        !Number.isInteger(this.diskSize) ||
        this.diskSize < 20 ||
        this.diskSize > 100
      ) {
        ElMessage({
          message: "请输入一个20到100之间的整数！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      ElMessageBox.confirm(
        `您即将创建大小为 ${this.diskSize} GB 的云硬盘，并挂载到实例，确认吗?`,
        "确认挂载",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.createAndAttachDisk();
        })
        .catch(() => {});
    },
    confirmAndExpand() {
      if (
        isNaN(this.diskNewSize) ||
        !Number.isInteger(this.diskNewSize) ||
        this.diskNewSize > 200
      ) {
        ElMessage({
          message: "数据盘最大支持200G",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      ElMessageBox.confirm(
        `即将对第一块云盘扩容，容量将增加到 ${this.diskNewSize} GB，确认吗?`,
        "确认扩容",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.expandDisk();
        })
        .catch(() => {});
    },

    async createAndAttachDisk() {
      if (isNaN(this.diskSize) || !Number.isInteger(this.diskSize)) {
        ElMessage({
          message: "请输入一个有效的整数！20 ~ 100",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskSize = 20;
        return;
      }
      if (this.diskSize < 20 || this.diskSize > 100) {
        ElMessage({
          message: "新硬盘大小: 输入 20 ~ 100 之间的整数！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskSize = 20;
        return;
      }

      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      try {
        const response = await axios.post(
          "http://sre.azlinux.com:5038/attach_disk_to_online_os",
          {
            email: email_user.substring(4),
            diskSize: this.diskSize,
          }
        );

        if (response.data.success) {
          ElMessage({
            message: "新硬盘挂载成功！",
            type: "success",
            duration: 2000,
            showClose: true,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "磁盘创建或挂载失败！" + response.data.message,
            type: "error",
            duration: 2000,
            showClose: true,
            offset: 240,
          });
        }
      } catch (error) {
        console.log(error);
        ElMessage({
          message: "出现错误：" + error.response.data.message,
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
    async expandDisk() {
      if (isNaN(this.diskNewSize) || !Number.isInteger(this.diskNewSize)) {
        ElMessage({
          message: "请输入一个有效的整数！且比旧的硬盘大小要大。",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskNewSize = this.diskSize + 10;
        return;
      }
      if (this.diskNewSize > 200) {
        ElMessage({
          message: "扩容到: 最大支持 200 GB 数据盘",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskNewSize = this.diskSize + 10;
        return;
      }

      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      try {
        const response = await axios.post(
          "http://sre.azlinux.com:5039/expand_disk",
          {
            email: email_user.substring(4),
            diskNewSize: this.diskNewSize,
          }
        );

        if (response.data.success) {
          ElMessage({
            message: response.data.message,
            type: "success",
            duration: 2000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "磁盘创建或挂载失败！",
            type: "error",
            duration: 2000,
            offset: 240,
          });
        }
      } catch (error) {
        console.log(error);
        ElMessage({
          message:
            "出现错误：" + error.response.data.message + " 请检查数值大小!",
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
    handleForeignChange() {
      if (this.foreign) {
        if (this.selectedOS == "OpenSUSE") {
          this.selectedOS = "Ubuntu";
          this.selectedOSVersion = "22.04";

          ElMessage({
            message:
              "【国外】站点当前不支持openSUSE, 以为您切换为【ubuntu:22.04】",
            type: "warning",
            duration: 5000,
            offset: 240,
          });
        }
        if (this.selectedOS == "CentOS") {
          this.osVersions.CentOS = ["stream_9"];
          this.selectedOSVersion = "stream_9";
          this.versions = this.osVersions.CentOS;
        }
      } else {
        this.osVersions.CentOS = ["stream_9", "stream_8", "7.9", "6.10"];

        if (this.selectedOS == "CentOS") {
          this.versions = this.osVersions.CentOS;
        }
      }
    },
    handleUpdateReleaseTime(newReleaseTime) {
      this.release_datetime = newReleaseTime;
      console.log("New release time after delay: ", newReleaseTime);
    },
    handleSelectedValue(newValue) {
      this.selectedValue = newValue;
      console.log("Change instance type to ", newValue);
    },
    handleDelayReleaseSuccess() {
      console.log("Delay release success.");
    },
    handleOSChange(value) {
      this.versions = this.osVersions[value] || [];
      this.selectedOSVersion = this.versions[0]; // 默认选择第一个版本

      if (this.selectedOS == "CentOS") {
        this.osVersions.CentOS = ["stream_9"];
        this.selectedOSVersion = "stream_9";
        this.versions = this.osVersions.CentOS;
      }
    },
    async destroyMyOnlineServer() {
      console.log("destroyMyOnlineServer");
      try {
        const email_user = localStorage.getItem("email");
        if (!email_user) {
          ElMessage({
            message: "发生了异常，建议退出账号，重新登录",
            type: "error",
            duration: 2000,
            offset: 240,
          });
          return;
        }

        const response = await axios.post(
          "http://sre.azlinux.com:5030/delete_online_server",
          {
            email: email_user.substring(4),
          }
        );
        ElMessage({
          message: "环境销毁成功！",
          type: "success",
          duration: 2000,
          offset: 240,
        });
        console.log("Success:", response.data);
        this.release_datetime = "";
      } catch (error) {
        console.error("Error:", error);
        ElMessage({
          message: "环境销毁失败！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
    getOnlineServer() {
      let url = "http://sre.azlinux.com:5030/get_online_server";
      let id = this.resId;
      let os_name = this.selectedOS;
      let os_version = this.selectedOSVersion;
      let flavor = this.selectedValue;
      let foreign = this.foreign;
      let email = localStorage.getItem("email");
      var email_user;

      if (email) {
        email_user = email.substring(4);
      } else {
        email_user = "";
        ElMessage({
          message: "请登录您的账号，【个人中心】",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        return;
      }

      let postData = {
        id: id,
        email: email_user,
        os_name: os_name,
        os_version: os_version,
        flavor: flavor,
        foreign: foreign,
      };

      this.connectButtonEnabled = true;
      this.isDestroyOSEnabled = true;
      this.delayReleaseButtonEnabled = true;
      this.isDataDiskEnabled = true;
      this.isNewDiskSizeEnabled = true;

      this.release_datetime = "waiting...";

      axios
        .post(url, postData)
        .then((response) => {
          this.responseData = response.data;
          console.log(response.data);
          if (response.data.vip_status == 0) {
            ElMessageBox.confirm("您的账号VIP状态已过期，需充值续费", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                console.log("MessageBox OK");
              })
              .catch(() => {
                console.log("MessageBox Cancel/Close");
              });
            return;
          }
          this.whost = this.responseData.data.whost;
          this.wport = this.responseData.data.wport;
          this.thost = this.responseData.data.thost;
          this.port = this.responseData.data.port;
          this.lname = this.responseData.data.username;
          this.passwd = this.responseData.data.passwd;
          this.release_datetime = this.responseData.data.release_datetime;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.hint2 =
            "------------------------ <br>" +
            "旧环境ip: " +
            error.response.data.ip_address +
            "<br>" +
            "账户名【sre】<br>" +
            "和密码【azLinux.com3】(默认)（或[个人中心]查看）<br>" +
            "释放时间: 【" +
            error.response.data.release_datetime +
            "】";
          this.thost = error.response.data.ip_address;
          this.release_datetime = error.response.data.release_datetime;
          this.passwd = error.response.data.sre_passwd;
          if (error.response.data.status == "error_exists") {
            ElMessageBox.alert(
              "1. 旧资源存在，请【连接环境】使用！<br>" +
                "2. 或者【销毁环境】，再执行【准备环境】。<br> " +
                this.hint2,
              "提示",
              {
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                  console.log("Clicked OK", action);
                },
              }
            );
          }
        });
    },
  },
  components: {
    BigTermsMacOS,
    ComputeSpecs,
    DelayReleaseButton,
  },
};
</script>
<style scoped>
.control-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 6px;
}

.version-select {
  width: 90px;
  margin-right: 1rem;
}

.button-group {
  margin-top: 4px;
  text-align: right;
}

.el-button {
  margin: 0 2px;
  padding: 2px;
}

.el-select {
  margin-top: 2px;
}

.el-radio-group {
  margin: 4px;
}

.el-checkbox {
  padding-right: 12px;
}
</style>
