<template>
  <div id="task-simple">
    <TaskCardSimple :steps="taskSteps" />
  </div>
</template>

<script>
import TaskCardSimple from "./base/TaskCardSimple.vue";

export default {
  name: "OnlineContainer",
  components: {
    TaskCardSimple,
  },
  data() {
    return {
      taskSteps: [
        "Step 1: Install Vue 3",
        "Step 2: Create a Vue component",
        "Step 3: Define the component's template",
        "Step 4: Style the component",
        "Step 5: Test the component",
      ],
    };
  },
};
</script>

<style>
#task-simple {
  text-align: left;
}
</style>
