<template>
  <el-container>
    <!-- 将父组件传入的背景色应用到 .termtitle -->
    <div :class="['termtitle', { wide: isWide }]" :style="{ backgroundColor: titleBgColor || defaultBgColor }">
      <!-- 使用 props.termTitle 动态显示终端标题 -->
      <span id="termname">【 {{ termTitle }} 】</span>
      <el-tooltip class="item" effect="dark" content="增加终端字体大小" placement="bottom">
        <el-button plain @click="increaseFontSize" size="small" style="
            font-size: 18px;
            margin: 2px;
            padding: 2px 6px;
            border: none;
            width: 1.4rem;
          ">+</el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="减少终端字体大小" placement="bottom">
        <el-button plain @click="decreaseFontSize" size="small" style="
            font-size: 12px;
            margin: 2px;
            padding: 2px 6px;
            border: none;
            width: 1.4rem;
          ">-</el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="终端字体设置为白色" placement="bottom">
        <el-button plain @click="setFontColorToWhite" size="small" style="
            background-color: black;
            border: none;
            font-size: 12px;
            margin: 2px;
            padding: 4px 6px;
            color: white;
          ">White</el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="在新标签页中打开链接" placement="bottom">
        <el-button :disabled="!labos" @click="openInNewTab" size="small" :style="{
          fontSize: '12px',
          margin: '2px',
          padding: '4px 6px',
          border: 'none',
          backgroundColor: !labos ? '#ccc' : '#90A4AE',  // 禁用时为灰色，启用时为蓝色
          color: !labos ? '#666' : '#fff'  // 禁用时为深灰色，启用时为白色
        }">
          新Tab打开
        </el-button>
      </el-tooltip>
    </div>
    <div :class="['realiframe', { wide: isWide }]">
      <iframe :src="labos" :style="{
        borderLeftColor: borderLeftColor,
        borderRightColor: borderRightColor,
        borderBottomColor: borderBottomColor
      }"></iframe>
    </div>
  </el-container>
</template>

<script>
export default {
  name: "BigTermsMacOSSimple",
  data() {
    return {
      labos: "",
      fontsize: 16,
      fontcolor: "lightgreen",
      isWide: true, // 默认宽屏状态
      defaultBgColor: "#262626", // 默认背景颜色
    };
  },
  props: {
    thost: {
      type: String,
      default: "",
    },
    whost: {
      type: String,
      default: "",
    },
    wport: {
      type: Number,
      default: 0,
    },
    port: {
      type: Number,
      default: 0,
    },
    lname: {
      type: String,
      default: "",
    },
    passwd: {
      type: String,
      default: "",
    },
    termTitle: {
      type: String,
      default: "SSH Terminal",
    },
    titleBgColor: {
      type: String,
      default: "",
    },
    borderLeftColor: {
      type: String,
      default: "red"
    },
    borderRightColor: {
      type: String,
      default: "black"
    },
    borderBottomColor: {
      type: String,
      default: "black"
    }
  },
  methods: {
    increaseFontSize() {
      this.fontsize += 2; // 每次增加2px
      console.log("Terminal fontsize: " + this.fontsize);
      this.connectBox(); // 更新labos
    },
    setFontColorToWhite() {
      this.fontcolor = "white";
      this.connectBox(); // 更新labos
    },
    decreaseFontSize() {
      if (this.fontsize > 12) {
        this.fontsize -= 2; // 每次减少2px，最小为12px
        console.log("Terminal fontsize: " + this.fontsize);
        this.connectBox(); // 更新labos
      }
    },
    connectBox() {
      this.labos = this.start_task();
      console.log(this.labos);
    },
    start_task() {
      return (this.labos =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd +
        "&title=sre-prod-labs" +
        "&fontcolor=" +
        this.fontcolor +
        "&fontsize=" +
        this.fontsize);
    },
    openInNewTab() {
      if (this.labos) {
        window.open(this.labos, '_blank'); // 在新标签页中打开链接
      }
    },
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

iframe {
  height: 620px;
  width: 100%;
  border-left: 4px solid;
  border-right: 4px solid;
  border-top: none;
  border-bottom: 4px solid;
  box-sizing: border-box;
  display: block;
  background-color: black;
}

.termtitle {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  max-width: 100%;
  text-align: left;
  transition: width 0.3s ease;
}

.realiframe {
  background-color: transparent;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: none;
  width: 100%;
  max-width: 100%;
  transition: width 0.3s ease;
  margin: 0;
  overflow: hidden;
}

#termname {
  font-size: 14px;
  margin: 0 28px;
}

.el-button {
  font-size: 14px;
  padding: 4px;
  margin: 0 1rem;
}
</style>
